<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">Статистика по поиску в ЛК</h4>

                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <label class="label" style="margin-right: 3px">c</label>
                                <div class="control">
                                    <datepicker placeholder="C" :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                                                v-model="from"></datepicker>
                                </div>
                            </div>
                            <div class="level-item">
                                <label class="label" style="margin-right: 3px">по</label>
                                <div class="control">
                                    <datepicker placeholder="По" :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                                                v-model="until"></datepicker>
                                </div>
                            </div>
                            <div class="level-item">
                                <label class="label" style="margin-right: 3px">Тип документа</label>
                                <div class="control">
                                    <div class="select">
                                        <select v-model="type">
                                            <option :value="0">Все</option>
                                            <option :value="1">Приемы</option>
                                            <option :value="2">Иследования</option>
                                            <option :value="3">Анализы</option>
                                            <option :value="4">Файлы</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="level-item">
                                <a class="button"  :class="{ 'is-loading': loading }" @click.prevent="filter">Показать</a>
                            </div>
                        </div>
                    </nav>

                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <div class="notification is-primary" v-if="query.search && !haveItems()">
                        Ничего не найдено
                    </div>

                    <spinner v-show="loading"></spinner>

                    <table class="table is-striped" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>Поисковая фраза</th>
                            <th>Кол-во запросов</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(mchistory, index) in items">
                            <td width="90%">{{ mchistory.query }}</td>
                            <td width="90%">{{ mchistory.count }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import mchistoryService from '../../services/mchistory'
import itemWithParamListViewMixin from '../../mixins/itemWithParamListView'
import Datepicker from 'vue-bulma-datepicker'
import moment from 'moment'

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      from: moment().subtract(1, 'd').format('YYYY-MM-DD'),
      until: moment().format('YYYY-MM-DD'),
      type: 0
    }
  },
  mixins: [itemWithParamListViewMixin(mchistoryService, 'mchistory', {from: this.from, to: this.until, type: this.type})]
}
</script>
